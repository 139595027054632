<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="客服名称" prop="kefu_name">
        <a-input v-model="form.kefu_name" placeholder="请输入客服名称" />
      </a-form-model-item>
      <a-form-model-item label="客服密码" prop="kefu_password">
        <a-input v-model="form.kefu_password" placeholder="请输入客服密码" />
      </a-form-model-item>
      <a-form-model-item label="所属分组" prop="group_id">
        <a-select placeholder="请选择审核状态" v-model="form.group_id">
          <a-select-option v-for="(d, index) in listGroup" :key="index" :value="parseInt(d.group_id)" >{{ d.group_name }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="是否激活" prop="kefu_status">
        <a-radio-group v-model="form.kefu_status" button-style="solid">
          <a-radio-button v-for="(d, index) in statusOptions" :key="index" :value="parseInt(d.value)">{{ d.label }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="最大服务人数" prop="max_service_num">
        <a-input v-model="form.max_service_num" placeholder="请输入最大服务人数" />
      </a-form-model-item>
      <a-form-model-item label="客服头像" prop="kefu_avatar">
        <a-upload  :show-upload-list="true"
                   :headers="headers"
                   name="file"
                   :action="fileUploadUrl"
                   :showUploadList="false"
                   :before-upload="beforeUpload"
                   @change="handleChange">
          <div class="ant-upload-preview">
<!--            <a-icon type="cloud-upload-o" class="upload-icon"/>-->
            <div class="mask">
              <a-icon type="plus" />
            </div>
            <a-avatar :size ="100" :src="kefu_avatar"/>
          </div>
        </a-upload>
      </a-form-model-item>

      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getPerson, addPerson, updatePerson } from '@/api/customerservice/person'
export default {
  name: 'CreateForm',
  props: {
    statusOptions: {
      type: Array,
      required: true
    },
    listGroup:{
      type: Array,
      required: true
    }
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        kefu_id: null,
        kefu_name: null,
        kefu_password:null,
        group_id: null,
        kefu_status: 1,
        max_service_num: 10,
        kefu_avatar: "/static/common/images/kefu/1.png",
      },
      fileUploadUrl: "/customerserviceurl/index/upload/uploadImg",
      headers: {
        "Access-Control-Allow-Origin": "*"
      },
      kefu_avatar:"/customerserviceurl/static/common/images/kefu/1.png",
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        kefu_name:[
          { required: true, message: '客服名称不能为空' }
        ],
        kefu_password:[
          { required: true, message: '客服密码不能为空' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    beforeUpload(file) {
      // 文件类型(file.type)、大小限制(file.size)
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('图片大小限制 2MB!')
      }
      return isLt2M
    },
    async handleChange(info) {
      console.log('info上传图片得到的数据', info)
      if (info.file.response) {
        // Component will show file.url as link
        this.form.kefu_avatar = info.file.response.data.src;
        this.kefu_avatar = "/customerserviceurl" + this.form.kefu_avatar;
      }
    },
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        kefu_id: null,
        kefu_name: null,
        kefu_password:null,
        group_id: null,
        kefu_status: 1,
        max_service_num: 10,
        kefu_avatar: "/static/common/images/kefu/1.png",
      }
      this.kefu_avatar = "/customerserviceurl" + this.form.kefu_avatar;
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row) {
      this.reset()
      this.formType = 2
      const kefu_code = row.kefu_code
      getPerson({"kefu_code":kefu_code}).then(response => {
        this.form = response.data
        this.kefu_avatar = "/customerserviceurl" + this.form.kefu_avatar;
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.kefu_id !== undefined && this.form.kefu_id !== null) {
            updatePerson(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addPerson(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>


<style lang="less" scoped>

.avatar-upload-wrapper {
  height: 200px;
  width: 100%;
}

.ant-upload-preview {
  position: relative;
  margin: 0 auto;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  box-shadow: 0 0 4px #ccc;

  .upload-icon {
    position: absolute;
    top: 0;
    right: 3px;
    font-size: 1rem;
    padding: 0.5rem;
    background: rgba(222, 221, 221, 0.7);
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
  .mask {
    opacity: 0;
    position: absolute;
    background: rgba(0,0,0,0.4);
    cursor: pointer;
    transition: opacity 0.4s;
    z-index: 1;
    &:hover {
      opacity: 1;
    }

    i {
      font-size: 2rem;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -1rem;
      margin-top: -1rem;
      color: #d6d6d6;
    }
  }

  .mask {
    width: 100%;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
  }
}
</style>
